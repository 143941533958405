<template>
  <div style="background-color: #F4F6F9;">
    <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
    <div
      class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center"
      style="background-color: #f4f6f9"
      v-if="row_data.length==0"
    >
     
      <div id="card1" class="card d-flex justify-content-center">
        <div class="py-4 px-4 mx-0 lg:mx-8" id="contact">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6" style="margin-top: -1.5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <img
                  src="images/eTicket_logo_new.png"
                  class="w-12"
                  alt="mockup mobile"
                />
              </div>
            </div>
            <div class="col-12 md:col-6" style="margin-top: 5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3"
              >
                <h4 style="color: #6b719b; font-size: 30px; font-weight: 700">
                   {{$t('search_ticket')}}
                </h4>
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-start md:justify-content-start md:mb-0 mb-3"
              >
                <div class="col-12">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-12" style="margin-top: 10%">
                      <span class="p-float-label">
                        <InputText id="name" type="text" v-model="name" />
                        <label for="name">{{$t('ticket_no')}}</label>
                      </span>
                    </div>
                    <!-- <div class="field col-12 md:col-12" style="margin-top: 3%">
                      <span class="p-float-label">
                        <InputText id="email" type="text" v-model="email_id" />
                        <label for="email">Email</label>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3"
              >
                <div class="col-12">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4"></div>
                    <div class="field col-12 md:col-4">
                      <Button
                        class="p-button-help btn_light_blue"
                        @click="submit_ticket()"
                      
                        :label="$t('search')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div>

    <div v-else>
      <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <!-- <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading> -->
        <!-- <Toast /> -->
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
              <p style="color: #6b719b; font-size: 20px; font-weight: 600">
                {{ $t('ticket_details') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
              <!-- <Button class="p-button-warning" iconPos="right" label="Edit Ticket" icon="pi pi-pencil"
                v-tooltip.bottom="'Edit Ticket'" @click="Edit_Ticket" size="small"
                style="border-color: #006b00!important;background-color: #006b00!important;" /> -->
              <Button   :label="$t('back')" 
              v-tooltip.bottom="$t('back')"  @click="Goback" size="small"
              style="
        padding: 5px 10px; 
        font-size: 16px; 
        line-height: 1.2; 
        box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549); 
        border-color: #00b0b0!important; 
        background-color: #00b0b0!important;" 
     />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-9">
          <div class="p-2 text-white bg-success ">
            <!-- <p style="color: white; font-size: 1rem; font-weight: 400">
              {{ product.ticket_no }}  {{ row_data[0].title }}
            </p> -->
            <!-- <p style="color: white; font-size: 1rem; font-weight: 400; display: flex; justify-content: space-between;">
              <span>{{ product.ticket_no }}</span>
            
            </p>
            <div style="text-align: right;width: 100%;">
              <Button icon="pi pi-comment" :label="$t('reply_comment_status')" v-tooltip.bottom="$t('post_reply_comment')" 
                @click="Post_Reply_view" class="w-auto" style="font-size:0.875rem;font-weight: 400;margin-left:auto;margin-right:0;"
                size="small" id="b_post" />
              </div> -->
              <div style="display: flex; justify-content: space-between; align-items: center; color: white; font-size: 2rem; font-weight: 400;">
            <span>{{ row_data[0].ticket_no }}</span>
            <!-- <Button 
              icon="pi pi-comment" 
              :label="$t('reply_comment_status')" 
              v-tooltip.bottom="$t('post_reply_comment')" 
              @click="Post_Reply_view" 
              class="w-auto" 
              style="font-size: 0.875rem; font-weight: 400; margin-left: auto;" 
              size="small" 
              id="b_post" 
            /> -->
          </div>

            
        
          </div>
         
          
          <Card v-if="show_post_reply">
            <template #content>
              <div class="col-12">
                <div class="p-fluid formgrid grid">
                  <!-- <div class="field col-6 md:col-6">

                    <Dropdown v-model="comment_type" :options="comment_type_dropdown" optionLabel="name"
                      placeholder="Select Comment Type" />
                  </div> -->
                  <!-- <div class="field col-6 md:col-6">
                    <Dropdown id="ticket_status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                      :value="ticket_status_dropdown._id" optionLabel="marathi" filter
                      :placeholder="$t('select_ticket_status')"  optionValue="_id" @change="handleTicketStatusChange">
                    </Dropdown>
                  </div> -->
                  <!-- <div class="field col-6 md:col-6" v-if="showRejectDropdown">
                    <Dropdown id="ticket_reject" v-model="ticket_reject_selected" :options="ticket_reject_dropdown"
                      :value="ticket_reject_dropdown._id" optionLabel="name" filter
                       :placeholder="$t('select_rejection_reason')" ></Dropdown>
                  </div> -->
                  <div class="field col-12 md:col-12">
                    <label class="mb-2" style=" font-size: 16px; font-weight: 600"> {{ $t('public_comm') }}</label>
                    <Editor v-model="post_public_comment" editorStyle="height: 200px" />
                  </div>

                  <div class="field col-12 md:col-12">
                    <label class="mb-2" style=" font-size: 16px; font-weight: 600">{{ $t('internal_comm') }}</label>
                    <Editor v-model="post_internal_comment" editorStyle="height: 200px" />
                  </div>


                  <!-- <div class="field col-12 md:col-2">
                    <Button :label="$t('post_reply')" 
                            v-tooltip.bottom="$t('post_reply')" 
                          @click="Post_Reply()"
                      class="p-button-warning btn_yellow" />
                  </div>&nbsp; -->
                  <!-- <div class="field col-12 md:col-2"> -->

                  <!-- <FileUpload class="p-button-Secondary btn_Secondary" mode="basic" name="model[]" :auto="true"
                      :custom-upload="true" choose-label="Attachment" @uploader="handleFileUpload($event)" /> -->
                  <!-- </div> -->
                  <div class="field col-12 md:col-2">
                    <Button :label="$t('Cancel')" v-tooltip.bottom="$t('Cancel')"  @click="cancel_Post_Reply_main"
                      class="p-button-danger btn_red" />
                  </div>
                </div>
              </div>
            </template>
          </Card>&nbsp;&nbsp;&nbsp;
          <!-- <SelectButton v-model="selectButtonValue1" :options="selectButtonValues1" optionLabel="name" /> -->


         
                <div class="card" 
     style="width: 100%; margin-right: 10px;" 
     
   >
                      <h3 v-if="row_data[0]"
                        style="text-align: center; padding: 7px; background-color: #f8f9fa; border: 1px solid #ddd; border-radius: 5px; color: #333; font-size: 18px;">
                        <!-- Public / Internal Comments -->
                      <!-- {{ $t('ticket_history') }} -->
                      {{ row_data[0].title }}
                      </h3>
                    
                      <div v-if ="comments.length>0">
                        <div v-for="(entry, index) in comments" :key="index" class="timeline-entry" :data-type="entry.comment_type"  >
                        <div class="timeline-time">{{ D_M_Y_formatDate(entry.created_time) }}<br>{{ entry.created_date }}</div>
                        <div class="timeline-comment">
                            <div class="timeline-marker"></div>
                            <div class="comment-box" :style="{ 
    width: '100%', 
    marginRight: '8px', 
    borderLeft: entry.comment_type === 'Public' ? '5px solid skyblue' : '5px solid grey' 
}">                                <div class="comment-header">{{ entry.status_name }}</div> 
                                <!-- <p class="m-0" style=" font-size: 16px; font-weight: 600">Description:</p>
              <p class="m-0" style=" font-size: 16px; font-weight: 600">
                {{ convertHtmlToText(row_data[0].description) }}
              </p> -->
                                <div class="comment-header">{{ entry.reject_reason }}</div> 
                                <p >
                      {{ entry.comment ? convertHtmlToText(entry.comment) : "" }}
                    </p>
 <!-- <p class="comment-header">{{ entry.username }}</p> -->
                                <div class="comment-meta">{{ entry.comment_type.charAt(0).toUpperCase() + entry.comment_type.slice(1) }} - {{ entry.username }}</div>
                            </div>
                        </div>
                    </div>

                      </div>
                      <div v-else>
                          {{ $t('no_comm') }}
                      </div>
                      <!-- {{ comments }} -->
                      
                   


            <!-- {{ comments }} -->



          </div>

        </div>
        <div class="field col-12 md:col-3">
          <div id="custom_card" class="card" v-if="row_data[0]">
            <div class="col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 800">
                   {{ $t('ticket_details') }}
                  </p>
                </div>
                <div class="field col-12 md:col-12">
                  <!-- <Dropdown id="ticket_status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                    :value="ticket_status_dropdown._id" optionLabel="marathi" filter @change="
                      update_status(product.ticket_no, ticket_status_selected, product.ticket_status)
                      "></Dropdown> -->

                </div>
              
                <div class="field col-12 md:col-12">
                  <!-- <span class="p-float-label">
                    <Dropdown id="priority_id" v-model="priority_selected" :options="ticket_priority_dropdown"
                      :value="ticket_priority_dropdown" optionLabel="name" filter @change="
                        update_priority(priority_selected.name, priority_selected, ticket_status_selected)
                        " />

                  </span> -->
                </div>
                <div  class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                   {{ $t('citizen_name') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].customer_name }}
                  </p>
                </div>
                <div  class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                   {{ $t('status') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ticket_status_name   }}
                   
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400;">
                    {{ $t('email') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].customer_email }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('dept') }}:
                  </p>

                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ticket_category_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('ward_no') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500;word-wrap: break-word">
                    {{ row_data[0].ward_no }}
                  </p>

                </div>
                <!-- <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Priority:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700;word-wrap: break-word">
                      {{ row_data[0].priority_name }}
                  </p>

                </div> -->
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                 {{ $t('emp_name') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    <!-- {{
                      row_data[0].agent_details == ""
                        ? "N/A"
                        : row_data[0].agent_details[0].agent_name
                    }} -->
                    {{
                       row_data[0].agent_details && row_data[0].agent_details.length>0
                        ? row_data[0].agent_details[0].agent_name
                        : ""
                    }}
                  </p>

                  <!-- <AvatarGroup class="mb-2">
                    <Avatar v-for="(info, index) in row_data[0].agent_details" :key="index" :image="info.agent_profile"
                      size="small" shape="circle" @click="show_Agent(info, index)" v-tooltip.bottom="info.agent_name"
                      style="cursor: pointer;" class="zoom"></Avatar>
                  </AvatarGroup> -->
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    {{ $t('created') }}:
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    {{ D_M_Y_formatDate(row_data[0].created_at) }}
                  </p>
                </div>
                <div class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                   {{ $t('Attachments') }}:
                  </p>
                </div>

                <div class="field col-12 md:col-6">
                  <AvatarGroup class="mb-2">
                    <Avatar v-for="(info, index) in row_data[0].attachments" :key="index" :image="info" size="small"
                      shape="circle" @click="show_Attchment(info, index)" style="cursor: pointer; height: 30px; width:30px"
                      class="zoom">
                    </Avatar>
                  </AvatarGroup>
                </div>
                <!-- <div class="field col-12 md:col-12">
                  <img
                    v-if="row_data[0].attachments"
                    :src="row_data[0].attachments"
                    
                    alt=""
                    class="zoom"
                    style="height: 50px; width: 50px"
                  />
                </div> -->
                <div class="field col-12 md:col-6" v-if="row_data[0].video">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                     {{ $t('video') }}:
                  </p>
                </div>

                <div v-if="row_data[0].video" class="field col-12 md:col-6">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 500">
                    <a :href="row_data[0].video" target="_blank">
                      <img style="width:50px" src="https://www.freeiconspng.com/thumbs/video-icon/video-icon-1.png"
                        alt="Video Thumbnail" class="video-thumbnail" />
                    </a>
                  </p>
                </div>

                <div class="field col-12 md:col-12">
                  <!-- <Button
                    id="b_delete"
                    label="Delete Ticket"
                    v-tooltip.bottom="'Delete Ticket'"
                    @click="DeleteTicket"
                    class="p-button-danger p-button-outlined "
                    size="small"
                  /> -->

                  <!-- ------------------ -->

                  <!-- <Button id="b_delete" label="Delete Ticket" v-tooltip.bottom="'Delete Ticket'"
                    @click="delete_toggle(item)" class="p-button-danger p-button-outlined " size="small" /> -->

                  <OverlayPanel ref="del">
                    <div class="flex flex-column gap-3 w-25rem">
                      <h5>  {{ $t('delete_ticket') }}</h5>

                      <div class="col-12">
                        <div class="p-fluid formgrid grid">
                          <div class="field col-12 md:col-12">
                            <span class="p-float-label">
                              <Textarea format="text" v-model="delete_remark">
                      </Textarea>
                              <label> {{ $t('remark') }}</label>
                            </span>
                          </div>

                          <div class="field col-12 md:col-4">
                            <Button :label="$t('delete')" @click="delete_product" class="p-button-primary btn_light_blue" />
                          </div>
                          <div class="field col-12 md:col-4">
                            <Button  :label="$t('Cancel')" @click="Cancel_delete" class="p-button-danger btn_red" />
                          </div>

                        </div>
                      </div>

                    </div>
                  </OverlayPanel>

                  <!-- ------------------ -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollTop />
  </div>
    </div>

    
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import ColorService from "../service/ColorService";
export default {
  name: "EticketRegister",
  data() {
    return {
        ColorService:null,
        color_data:[],
        row_data:[],
      isLoadingModel: false,
      fullPage: false,
      name: "",
    //   email_id: "",
      password: "",
      conf_password: "",
      phone: "",
      client_id: "",
      
      comments: [],
    };
  },
  mounted() {
    this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });
    this.client_id = localStorage.getItem("client_id");
    this.client_id = "65a4f82f6177e69880ece5d6";
    this.row_data=[];
  },
  created(){
    this.ColorService = new ColorService();
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
  methods: {
    async Goback() {
      this.row_data=[];
    },
    getcolorcode(name)
      {
        var name=this.convert_name(name)
        var temp=name.substring(0, 1);
        for (let index = 0; index < this.color_data.length; index++) 
        {
         if (this.color_data[index].name==temp) 
         {
            return this.color_data[index].code;
         }
          
        }
      },
    convert_name(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
        
      },
    async show_attachment(url)
    {
      window.open(url);
    },
    
    convertHtmlToText(html) {
      var temporaryElement = document.createElement("div");
      temporaryElement.innerHTML = html;
      return temporaryElement.textContent || temporaryElement.innerText || "";
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    async reset_form() {
      (this.name = "");
    },
    async submit_ticket() {
      if (this.name == "") {
        this.$toast.add({
          severity:"error",
          summary: this.$t('error'),
         // detail: "Please Enter Ticket No",
          detail: this.$t('please_enter_ticket_no'),

          life: 3000,
        });
        return false;
      }
    //   if (this.email_id == "") {
    //     this.$toast.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: "Please Enter Email ID",
    //       life: 3000,
    //     });
    //     return false;
    //   }
      var payload = {
        ticket_no: this.name,
        // email_id: this.email_id,
        client_id: this.client_id,
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
       };
        this.isLoadingModel = true;
        var promise = apis.getticketDetails(payload);
        promise.then((response) => {
        this.isLoadingModel = false;
        if(response.data.data.length>0)
        {
          this.row_data = response.data.data;
          this.comments=response.data.ticket_comments
        }
        else
        {
            this.$toast.add({
                  severity:"error",
                  summary: this.$t('error'),
          
                 detail: this.$t('ticket_not_found'),
                  //detail: "Ticket Details Not Found",
                  life: 3000,
              });
        }
        });
    },
  },
};
</script>
<style scoped>
#card1 {
  background-color: #fff;
  border-top: 3px solid #6b719b;
}
.link-style {
  color: #6b719b;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.link-style:hover {
  text-decoration: underline; /* Add underline on hover */
}
@media screen and (min-width: 992px) {
  .lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }
}

#card_custom{
  
  border-top: 3px solid #6b719b;
}
.left-aligned-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Left align the timeline items */
}
.red-label {
  color: red;
}

.green-label {
  color: green;
}

.default-label {
  color: gray;
}

.custom-card {
  background-color: #f7f5f5;
  /* Light grey background */
  border: 1px solid #ccc;
  /* Border color */
  padding: -4rem;
  border-radius: 9px;
  /* Rounded corners */
}

.timeline-item {


  display: flex;
  align-items: right;
  justify-content: space-between;
  padding: 20px 25px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  min-height: 70px;
  flex-direction: column;
}

/* Avatar Styling */
/* .avatar-container {
  display: flex;
 
  margin-right: 16px;
} */

/* .avatar {
  position: absolute;
  top: 10px;
  border-radius: 5%;
  width: 10px;
  height: 10px;
  object-fit: cover;
} */

/* .avatar-fallback {
  display: flex;
  top: 10px;
  background-color: #cfcfcf;
  color: #fff;
  border-radius: 5%;
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
} */

/* Comment Content */
.comment-container {
  flex-grow: 1;
  margin-right: 20px;
}

.username {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  top: 10px;
}

.comment-text {
  font-size: 12px;
  color: #666;
  margin-top: 6px;
  /* text-align: justify; */
  justify-content: center;

}




.p-button-rounded.p-button-danger {
  border-radius: 50%;
  /* Ensures the button is circular */
  padding: 2px;
  /* Adjust padding for a smaller button */
  font-size: 12px;
  /* Adjust the font size for a smaller button */
  width: 20px;
  /* Set a fixed width */
  height: 20px;
  /* Set a fixed height */
  display: flex;
  /* Center the icon inside the button */
  justify-content: center;
  align-items: center;
}

.avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between elements */
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-fallback {
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-delete {
  margin-left: 110px;
}



#custom_card {
  border-top: 3px solid #6b719b;
}

.card_custom {
  border-bottom: 3px solid #6b719b;
}

.bg-success {
  background-color: #00b064 !important;
}

.bg-success2 {
  background-color: #00b0b0 !important;
}

.container-comment {
  display: flex;
  gap: 20px;
}

.column-comment {
  background-color: #d6e9d1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.column-1 {
  flex: 3;
  position: relative;
}

.timeline {
  position: relative;
  border-left: 2px solid #666;
  padding-left: 20px;
  margin-top: 20px;
}

.timeline-entry {
  display: flex;
  align-items: flex-start;
}

.timeline-time {

  padding-right: 10px;
  color: #555;
  font-size: 14px;
  width: 120px;
  text-align: right;
  height: 100%;
}

.timeline-marker {
  width: 12px;
  height: 12px;
  background-color: #666;
  border-radius: 50%;
  position: relative;
  top: 5px;
  float: left;
  position: absolute;
  margin-top: 0;
  margin-left: -7px;
}

.timeline-comment {
  display: flex;
  position: relative;
  border-left: 2px solid #666;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-grow: 1;
}

.comment-box {
  background-color: #fff;
  border: 0px solid #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  flex-grow: 1;
  margin-left: 10px;
  margin-bottom: 10px;
}

.comment-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.comment-meta {
  font-size: 12px;
  color: #777;
  text-align: right;
}

.column-2 {
  flex: 1;
  height: 100%;
}

.glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

#b_delete:hover {
  background-color: red;
  color: white
}

#b_post:hover {
  background-color: white;
  color: #00b0b0 !important
}

.zoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}

.bold-date {
  font-weight: bold;
}

.left-aligned-timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Left align the timeline items */
}
</style>